import { createStyles, makeStyles } from '@material-ui/core/styles'
import Dot from '@material-ui/icons/FiberManualRecord'
import { ReactElement } from 'react'

import Block from 'src/components/layout/Block'
import Img from 'src/components/layout/Img'
import { border, fancy, screenSm, warning } from 'src/theme/variables'

import KeyIcon from '../assets/key.svg'
import TriangleIcon from '../assets/triangle.svg'

const styles = createStyles({
  root: {
    display: 'none',
    [`@media (min-width: ${screenSm}px)`]: {
      display: 'flex',
    },
  },
  dot: {
    position: 'relative',
    backgroundColor: '#ffffff',
    color: fancy,
  },
  key: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: border,
  },
  warning: {
    position: 'relative',
    top: '-2px',
  },
})

const useStyles = makeStyles(styles)

const buildDotStyleFrom = (size, right, mode) => ({
  width: '12px',
  height: '12px',
  borderRadius: `${size}px`,
  top: '2px',
  right: '8px',
  color: mode === 'error' ? fancy : warning,
})

type Props = {
  center?: boolean
  circleSize?: number
  dotRight?: number
  dotSize?: number
  dotTop?: number
  hideDot?: boolean
  keySize: number
  mode?: string
}

export const KeyRing = ({ dotRight, dotSize, dotTop, hideDot = false, mode }: Props): ReactElement => {
  const classes = useStyles(styles)
  const dotStyle = buildDotStyleFrom(dotSize, dotTop, dotRight)
  const isWarning = mode === 'warning'
  const img = isWarning ? TriangleIcon : KeyIcon

  return (
    <Block className={classes.root}>
      <Img
        alt="Status connection"
        className={isWarning ? classes.warning : undefined}
        height="32px"
        src={img}
        width="32px"
      />
      {!hideDot && <Dot className={classes.dot} style={dotStyle} />}
    </Block>
  )
}
